/** @jsx jsx */
import { jsx } from "@emotion/core"
import { useEffect } from "react"
import introArt from "../images/intro-beta.jpg"

import { motion, useMotionValue } from "framer-motion"
import { withAssetPrefix } from "gatsby"

export default function Intro({ open }) {
  const blur = useMotionValue("blur(0)")

  useEffect(() => {
    if (open) blur.set("blur(10px)")
    else blur.set("blur(0)")
  }, [open])

  return (
    <motion.div
      css={{
        position: "absolute",
        left: 0,
        top: 0,
        zIndex: 3,
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: `url(${introArt}) center center no-repeat`,
        backgroundSize: "cover",
        backgroundColor: "#89C2C0",
        "@media(max-width: 767px)": {
          backgroundPosition: "bottom center",
          backgroundSize: "auto 90%",
        },
      }}
      style={{
        filter: blur,
      }}
    >
      <h1
        css={{
          position: "absolute",
          fontSize: "92px",
          lineHeight: "141px",
          color: "white",
          top: "47%",
          transform: "translateY(-50%)",
          "@media(max-width: 1023px)": {
            fontSize: 85,
            lineHeight: "118px",
          },
          "@media(max-width: 767px)": {
            fontSize: 54,
            lineHeight: "57px",
            top: "54.2%",
          },
        }}
      >
        Be Grizzlee.
      </h1>
    </motion.div>
  )
}
