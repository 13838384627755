/** @jsx jsx */
import { jsx } from "@emotion/core"
import { useEffect, useRef, useCallback, useState } from "react"

import aboutUs from "../videos/h264.mp4"

import Intro from "../components/intro"
import Nav from "../components/nav"
import End from "../components/end"

import Layout from "../components/layout"
import SEO from "../components/seo"
import dot from "../images/dot.png"

import { motion, useMotionValue, useViewportScroll } from "framer-motion"

const useScrollTimeline = cb => {
  const { scrollYProgress, scrollY } = useViewportScroll()
  const callback = useCallback(cb, [cb])
  useEffect(() => {
    return scrollYProgress.onChange(() =>
      callback({
        progress: scrollYProgress.get(),
        y: scrollY.get(),
        height: scrollY,
      })
    )
  }, [callback])
}

const fps = 1 / 30

function useWindowSize() {
  function getSize() {
    const isClient = typeof window === "object"
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    }
  }

  const [windowSize, setWindowSize] = useState(getSize)

  useEffect(() => {
    const isClient = typeof window === "object"
    if (!isClient) {
      return false
    }

    function handleResize() {
      setWindowSize(getSize())
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return windowSize
}

const Video = ({ src, open }) => {
  const ref = useRef(null)
  const [duration, setDuration] = useState(10)
  const update = useCallback(
    ({ progress, height }) => {
      const adjustedProgress = (progress * 20 - 1) / 18
      const frame = Math.round(adjustedProgress * duration * 30)

      ref.current.currentTime = frame / 30
    },
    [duration]
  )

  const timeline = useScrollTimeline(update)
  const blur = useMotionValue("blur(0)")

  useEffect(() => {
    if (open) blur.set("blur(10px)")
    else blur.set("blur(0)")
    if (ref.current && ref.current.currentTime === 0) {
      ref.current.play()
      setTimeout(() => ref.current.pause(), 0)
    }
  }, [open])

  return (
    <motion.video
      style={{
        position: "fixed",
        top: 0,
        left: "50%",
        width: "100%",
        maxWidth: 1920,
        height: "100vh",
        transform: "translate3d(-50%, 0, 0)",
        zIndex: 1,
        filter: blur,
      }}
      ref={ref}
      autoload="autoload"
      onCanPlay={() => setDuration(ref.current.duration)}
      muted={true}
      playsInline
      playsinline
    >
      <source src={src} type="video/mp4" />
    </motion.video>
  )
}

const IndexPage = () => {
  const { height } = useWindowSize()
  const getHeight = () => (height ? height * 20 : 20 * 1000)
  const [open, setOpen] = useState(false)

  return (
    <Layout>
      <SEO title="Home" />

      <motion.div
        style={{
          height: getHeight(),
          position: "relative",
        }}
      >
        <Video src={aboutUs} open={open} />
        <Intro open={open}></Intro>
        <End open={open}></End>
      </motion.div>
      <Nav onToggle={o => setOpen(o)} />
      <div
        css={{
          position: "fixed",
          top: 20,
          left: 20,
          width: 40,
          height: 40,
          background: `url(${dot}) center center no-repeat`,
          backgroundSize: "contain",
          cursor: "pointer",
          zIndex: 5,
        }}
        onClick={() => window.scrollTo(0, 0)}
      ></div>
      {/* <svg xmlns="http://www.w3.org/2000/svg">
        <defs>
          <filter id="blurlayer" width="110%" height="100%">
            <feGaussianBlur stdDeviation="4" result="blur" />
            <feImage
              id="feimage"
              href="data:image/svg+xml;charset=utf-8;base64,PHN2ZyB3aWR0aD0iMTAyNCIgaGVpZ2h0PSIzMDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQogPGc+DQogIDx0aXRsZT5iYWNrZ3JvdW5kPC90aXRsZT4NCiAgPHJlY3QgZmlsbD0ibm9uZSIgaWQ9ImNhbnZhc19iYWNrZ3JvdW5kIiBoZWlnaHQ9IjMwMiIgd2lkdGg9IjEwMjYiIHk9Ii0xIiB4PSItMSIvPg0KIDwvZz4NCg0KIDxnPg0KICA8dGl0bGU+TGF5ZXIgMTwvdGl0bGU+DQogIDxyZWN0IGlkPSJzdmdfMSIgaGVpZ2h0PSIxMzMiIHdpZHRoPSIxMDI0IiB5PSI4NC41IiB4PSIwIiBmaWxsLW9wYWNpdHk9Im51bGwiIHN0cm9rZS1vcGFjaXR5PSJudWxsIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlPSJudWxsIiBmaWxsPSIjMDAwMDAwIi8+DQogPC9nPg0KPC9zdmc+"
              x="0"
              y="0"
              height="300px"
              result="mask"
            />
          </filter>
        </defs>
      </svg> */}
    </Layout>
  )
}

export default IndexPage
