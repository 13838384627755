/** @jsx jsx */
import { jsx } from "@emotion/core"
import React, { useEffect, useState } from "react"

import { motion } from "framer-motion"

export const Contact = ({ open, onClick, absolute = false }) => {
  return (
    <motion.div
      css={{
        position: absolute ? "absolute" : "fixed",
        display: "flex",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        opacity: 0,
        backgroundColor: "rgba(0,0,0, 0.4)",
        zIndex: 30,
        pointerEvents: open ? "all" : "none",
        justifyContent: "center",
        alignItems: "center",
      }}
      animate={{ opacity: open ? 1 : 0 }}
      onClick={onClick}
    >
      <div
        css={{
          maxWidth: 800,
          textAlign: "center",
          color: "white",
          fontSize: 20,
          fontWeight: 100,
          fontFamily: '"Hevletica Neue", Helvetica, sans-serif',
          width: "100%",
        }}
      >
        <h2
          css={{
            width: "100%",
            fontFamily: '"Beatrice-Bold", sans-serif',
            fontSize: 48,
          }}
        >
          Be in touch.
        </h2>
        <div
          css={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            lineHeight: "27px",
            "h3,p": { margin: 0, padding: 0 },
            p: { marginBottom: 20 },
            "@media(max-width: 767px)": { fontSize: 16, lineHeight: "22px" },
          }}
        >
          <div css={{ width: "25%", minWidth: 180 }}>
            <h3>Los Angeles</h3>
            <p>+1 310 305 6800</p>
          </div>
          <div css={{ width: "25%", minWidth: 180 }}>
            <h3>London</h3>
            <p>+44 0 20 7964 8400</p>
          </div>
          <div css={{ width: "25%", minWidth: 180 }}>
            <h3>Shanghai</h3>
            <p>+86 21 2223 0600</p>
          </div>
          <div css={{ width: "25%", minWidth: 180 }}>
            <h3>Singapore</h3>
            <p>+65 6225 5288</p>
          </div>
        </div>
        <div
          css={{
            display: "flex",
            width: "100%",
            justifyContent: "space-around",
            textAlign: "center",
            flexWrap: "wrap",
            marginTop: 40,
            fontSize: 20,
            "h3,p": { margin: 0, padding: 0 },
            p: { marginBottom: 20 },
            "@media(max-width: 767px)": { fontSize: 12, lineHeight: "22px" },
          }}
        >
          <div
            css={{ width: "30%", "@media(max-width: 767px)": { width: "50%" } }}
          >
            <h3
              css={{
                fontSize: 22,
                "@media(max-width: 767px)": { fontSize: 18 },
              }}
            >
              Recruiting:
            </h3>
            <p>recruiting@begrizzlee.com</p>
          </div>
          <div
            css={{ width: "30%", "@media(max-width: 767px)": { width: "50%" } }}
          >
            <h3
              css={{
                fontSize: 22,
                "@media(max-width: 767px)": { fontSize: 18 },
              }}
            >
              Inquiries:
            </h3>
            <p>inquiries@begrizzlee.com</p>
          </div>
        </div>
      </div>
    </motion.div>
  )
}

export default function Nav({ onToggle }) {
  const [open, setOpen] = useState(false)
  useEffect(() => onToggle(open), [open, onToggle])
  return (
    <>
      <nav
        css={{
          position: "fixed",
          left: 0,
          bottom: 0,
          top: 0,
          zIndex: 4,
          width: "100%",
          height: "100vh",
        }}
      >
        <div
          css={{
            width: "100vh",
            height: "100vw",
            position: "absolute",
            transform: "rotate(-90deg) translateX(-100%)",
            transformOrigin: "0 0%",
            fontSize: 28,
            padding: 20,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <motion.a
            css={{
              cursor: "pointer",
              color: "#B49B73",
              // textShadow: `0 0 11px rgba(255,255,255, 0.15)`,
              "@media(max-width: 1024px)": { margin: "0 44px", fontSize: 28 },
              "@media(max-width: 767px)": { fontSize: 18 },
            }}
            onClick={() => setOpen(!open)}
            animate={{ opacity: open ? 0 : 1 }}
          >
            Contact.
          </motion.a>
        </div>
      </nav>

      <Contact open={open} onClick={() => setOpen(false)}></Contact>
    </>
  )
}
