/** @jsx jsx */
import { jsx } from "@emotion/core"
import React, { useEffect } from "react"
import Image from "../components/image"
import endImage from "../images/intro-beta.jpg"

import { Contact } from "./nav"

import { motion, useMotionValue } from "framer-motion"

export default function End({ open }) {
  const blur = useMotionValue("blur(0)")

  useEffect(() => {
    if (open) blur.set("blur(10px)")
    else blur.set("blur(0)")
  }, [open])

  return (
    <div
      style={{
        position: "absolute",
        left: 0,
        bottom: 0,
        zIndex: 2,
        width: "100%",
        height: "100vh",
        zIndex: 5,
        overflow: "hidden",
      }}
    >
      <motion.div
        css={{
          position: "absolute",
          left: "-5%",
          bottom: "-5%",
          zIndex: 2,
          width: "110%",
          height: "110vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: `url(${endImage}) center center no-repeat`,
          backgroundSize: "cover",
          backgroundSize: "cover",
          backgroundColor: "#89C2C0",
          "@media(max-width: 767px)": {
            backgroundPosition: "bottom center",
            backgroundSize: "auto 90%",
          },
          filter: "blur(10px)",
        }}
      >
        {/* <Image /> */}
      </motion.div>
      <Contact open absolute></Contact>
      <motion.footer
        css={{
          position: "absolute",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSize: 20,
          display: "flex",
          justifyContent: "center",
          bottom: 20,
          zIndex: "100",
          fontWeight: 400,
          width: "100%",
          color: "white",
          "> div": {
            padding: "0 20px",
            a: {
              color: "white",
              textDecoration: "none",
              ":hover": {
                boxShadow: "0px 2px 0px -1px white",
              },
            },
          },
        }}
      >
        <div>&copy; Be Grizzlee. 2023</div>
        <div>
          <a href="/tos" target="_blank">
            Terms of Service
          </a>
        </div>
        <div>
          <a href="/privacy" target="_blank">
            Privacy Policy
          </a>
        </div>
      </motion.footer>
    </div>
  )
}
